<template>
  <div class="text-xs-right mt-3">
    <x-pagination
      v-bind="scheme"
      class="table-pagination"
    />
  </div>
</template>

<script>
import { DEFAULT_PAGE_SIZE } from '@/const'

export default {
  name: 'Pagination',
  props: {
    query: {
      type: Object,
      default: () => ({})
    },
    fetch: {
      type: Function,
      required: true
    },
    callback: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      scheme: {
        value: 1,
        length: 1,
        size: DEFAULT_PAGE_SIZE,
        totalVisible: 5,
        click: this.fetchPage
      }
    }
  },
  computed: {
    offset () {
      return this.scheme.size * (this.scheme.value - 1)
    }
  },
  watch: {
    query () {
      this.fetchPage(1)
    }
  },
  methods: {
    async fetchPage (pageIndex = 1) {
      this.$store.loading = true
      try {
        const { data } = await this.fetch({
          ...removeEmpty(this.query),
          offset: (pageIndex - 1) * DEFAULT_PAGE_SIZE,
          limit: DEFAULT_PAGE_SIZE
        })
        this.scheme.length = Math.ceil(data.total / DEFAULT_PAGE_SIZE)
        this.scheme.value = pageIndex
        this.callback(data)
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    },
    refresh () {
      return this.fetchPage(this.scheme.value)
    }
  }
}

const removeEmpty = (obj) => {
  const keys = Object.keys(obj).filter(key => obj[key] !== undefined && obj[key] !== null)
  return keys.reduce((o, key) => {
    o[key] = obj[key]
    return o
  }, {})
}
</script>
